import React, {Component} from 'react'
import {Map, Marker, Polyline, Popup, TileLayer} from 'react-leaflet';

export default class MapContainer extends Component {
  state = {
    center: [48.122916, 11.587102],
    zoom: 13,
    height: 500
  }
  map = React.createRef();

  updateDimensions() {
    const height = window.innerWidth >= 992 ? window.innerHeight : 400
    this.setState({height: height})
  }

  componentDidMount() {
    const {positions = []} = this.props;
    if (this.map.current) {
      const map = this.map.current.leafletElement;
      map.fitBounds(positions);
    }
    this.updateDimensions()

    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  renderMarkers = () => {
    const {positions = []} = this.props;
    return positions.map(pos =>
        <Marker position={pos}>
          <Popup>
            <span>
              A pretty CSS3 popup. <br/> Easily customizable.
            </span>
          </Popup>
        </Marker>
    )


  }


  render() {
    const {positions = []} = this.props;
    const browser = typeof window !== 'undefined';
    const {height} = this.state;
    const markers = this.renderMarkers();
    return (
        <div style={{width: '100%', height: height, display: 'grid'}}>
          {browser &&
          <Map center={positions[0] || this.state.center} zoom={this.state.zoom} ref={this.map}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
            />
            <Polyline positions={positions} color={'#00afdc'}/>
            {markers}
          </Map>
          }

        </div>
    )
  }
}
